import React, { useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../db/redux/store";
import { Dispatch } from "redux";
import Logout from '../../../../assets/icons/exit.png';

 class Instruction extends React.PureComponent<any,any> {
    constructor(props:any) {
        super(props);
     
    };


    _next = () =>{
    
        return {
            go: true,
            value:{
              
            }
        };
    }

   

    render(): React.ReactNode {
        return(
             <div className="w-full h-full flex px-4 py-6 flex-col overflow-scroll action_container pb-40"> 
                <span className="font-quick text-text text-[14px] leading-[17px] font-medium whitespace-pre-wrap">
                    <span className="font-bold">Before you start, please note:</span>
                    <ul className='list-disc pl-4 space-y-2 mt-2'>
                        <li>Once you complete a session, it will not be saved. You can however print a PDF summary of the session before starting a new session. A next release will have your permanent personal library of all sessions.</li>
                        <li>You can clear the data you have entered at any time by using the icon <span className="w-3.5 bg-cover h-3.5 mx-1" style={{backgroundImage:`url(${Logout})`}}>    </span> in the top right corner of the screen. Remember this means you'll be starting a new circumstance when you log back in.</li>
                        <li>If you need to take a break or leave at any time while working through a circumstance, you can save the data entered and resume later by:</li>
                        <ul className='list-disc pl-4 space-y-2 mt-2'>
                            <li><b>Using a Smartphone</b> - Close your phone’s internet browser window (do not log out, as logging out will result in the data being lost, and you will need to start again).</li>
                            <li><b>Using a Computer or Laptop</b> - Click the red <span className="text-red-700">ⓧ</span> on your browser window (do not log out, as logging out will result in the data being lost, and you will need to start again).</li>
                        </ul>
                    </ul>
                </span>
            </div>
        )
    }
};

const map_state_to_props = (state:RootState) => {
    return {
   
    }
};

const map_dispatch_to_props = (dispatch:Dispatch) => {
    return {

    }
};

export default connect(map_state_to_props,map_dispatch_to_props,null,{forwardRef:true})(Instruction);

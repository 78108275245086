import React from 'react';
import BG from '../../../../assets/bg/intro.png';
import IntroVideoImage from '../../../../assets/static/intro.jpg';
import { cache_image } from '../../../../utils/cache/image';
import { Link } from 'react-router-dom';
import { privacy_info } from '../../../../utils/navigation/path';


export default class Introduction extends React.PureComponent<any,any>{

    preload = () => {
        cache_image([
            require('../../../../assets/icons/inner_self.png'),
            require('../../../../assets/icons/external_self.png')
        ])
    };

    componentDidMount(): void {
        this.preload();
    }
    
    _next = ()=> {
        return {
            go:true,
            value:{
                done:true
            }
        };
    }

    render(): React.ReactNode {
        return (
            <div  style={{backgroundImage:`url(${BG})`}} className="w-full h-full pb-40 bg-bg  intro_shadow rounded-2xl flex flex-col overflow-scroll intro_bg pt-8 px-4">
                <span className="text-white font-quick text-2xl text-center flex leading-[25px] font-semibold self-center whitespace-pre-wrap">{`Welcome to the\nVerve Pocket Coach!`}</span>
                <div  style={{backgroundImage:`url(${IntroVideoImage})`}} className="flex justify-center items-center w-full !h-40 mt-6 mb-4 intro_bg">
                    {/* <img src={require('../../../../assets/icons/play.png')} className="w-16"/> */}
                    <div className='w-full h-44'/>
                </div>
                <span className="text-white font-quick text-[16px] !leading-[22px] flex flex-col font-medium">
                    Verve is your personal coaching session tool to improve how you approach challenges and achieve your goals more effectively. Whether it’s progress at work, in your personal life, or in social situations, it will help empower you to overcome obstacles with clarity, confidence, and purpose.
                    <br />
                    <br />
                    <b>How It Works</b>
                    <ul className='list-disc pl-4 space-y-2 mt-2'>
                        <li>Start Your Session:
                            <ul className='list-disc pl-6'>
                                <li>Choose between:
                                    <br />
                                    <b>New Circumstance:</b> Define, explore, and assess the impact of a specific challenge<br/>
                                    <b>Prepare Myself:</b> Quickly align your mindset for an upcoming event, such as a presentation, negotiation, or difficult conversation
                                </li>
                            </ul>
                        </li>
                        <li>Deconstruct Your Perception
                             <ul className='list-disc pl-6'>
                                 <li>Identify the thoughts, feelings, and actions driving your approach.</li>
                                <li>Uncover hidden beliefs and assumptions holding you back</li>
                            </ul>
                        </li>
                        <li>Reconstruct Your Perception
                              <ul className='list-disc pl-6'>
                                   <li>Replace disempowering beliefs with empowering ones.</li>
                                <li>Create a clear action plan to approach your challenge with renewed confidence and purpose</li>
                              </ul>
                        </li>
                       
                    </ul>
                    <br />
                    Each session guides you into an empowered mindset and new and more balanced approach to achieve breakthroughs, and you can print out a PDF of the Circumstance journey.
                    <br />
                    <br />
                    <b>Data Privacy Assured</b>
                    We do not collect, store or use any of your personal information. This means that you can be as personal and truthful as you need to be, which will really help you experience the benefits and power of the Pocket Coach. For further details on privacy and data security, please visit <Link to={privacy_info} state={{no_footer:true}} className='link_text'>pocketcoach.verveeducation.com/privacy.</Link>
                    <br />
                    <br />
                    <b>Additional Features for Organisation Users</b>
                    <ul className='list-disc pl-4 space-y-2 mt-2'>
                        <li><b>Accountability Support:</b> Stay connected with your assigned partner and group leader for ongoing support and guidance. If you haven’t been notified of your group leader and partner, contact your HR Manager.</li>
                        <li><b>Monthly Goals:</b> Set a target of completing one New Circumstance or Prepare Myself session every fortnight (or as agreed with your manager or assigned leader).</li>
                    </ul>
                    <br />
                    <b>Your Feedback is welcomed</b>
                    We’d love to receive any feedback on your experience:
                    <ul className='list-disc pl-4 space-y-2 mt-2'>
                        <li>Technical issues (e.g., slow responses or errors)</li>
                        <li>Confusing messages or unclear steps</li>
                        <li>Features you’d love to see added</li>
                    </ul>
                    <br />
                    Please use the orange "Give Feedback" button that you will see on the right hand side of the browser.
                    <br />
                    <br />
                    If you enjoyed the Pocket Coach, we would be happy to receive your testimonial at <a href='mailto:feedback@verveeducation.com' className='link_text'>feedback@verveeducation.com</a>
                    <br />
                    <br />
                    <b>Get Started Today</b>
                    Start your session below and enjoy unlocking new perspectives and possibilities!
                </span>
            </div>
        )  
    }
}

import React from 'react';
import Button from '../../common/componenets/button';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { on_user_login } from '../../db/redux/features/auth/auth.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { login_path } from '../../utils/navigation/path';

const PrivacyInfo = () => {
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const _on_dont = () => {
        signOut();
    };

    // get passed state through navigation
    const location = useLocation();
    const state = location.state;


    const _on_accept = async() => {
        try{
             Auth.updateUserAttributes(user, {
             'custom:accepted':'true'
              });
              dispatch(on_user_login({uid:user.username||'',has_accepted_terms:true}));
              navigate(login_path);
        }catch(err){
        }
    };

    const _navigate_back = () => {
        navigate('/app/circgen_intro');
    }

    return (
        <div className='flex w-full h-full flex-col'>
            <img onClick={_navigate_back} className='w-5 m-5 mb-2 -rotate-180' src={require('../../assets/icons/next.png')}/>
          <div className='w-full h-full p-5 flex flex-col overflow-scroll'>
                <span className='font-quick text-[#4A3D2E] font-bold text-md text-center whitespace-pre-wrap'>{`Terms and Conditions for the\nVerve Pocket Coach`}</span>
                <br />
                Before you proceed, please carefully read and understand the following terms and conditions governing your use of the Verve Pocket Coach. By accessing and using the Application, you agree to be bound by these terms and conditions.
                <br />
                <br />
                <b>Privacy and Data Security</b>
                <ol className='list-none pl-4 space-y-1 mt-2'>
                    <li>a. We understand the importance of your privacy and the security of your personal information. We commit to handle your data in accordance with applicable privacy laws and regulations.</li>
                    <li>b. We will not be collecting or storing any of your personal data or content from the Pocket Coach use.</li>
                    <li>c. Data from your Pocket Coach use might be stored in your browser's cache. If you close a tab without logging out and reopen it, previously entered data will reappear. To delete this data, clear your browser's cache. Logging out of the Pocket Coach via the top left icon will remove Application data from the local cache.</li>
                    <li>d. We will be collecting and storing all feedback you provide for the purpose of improving the product.</li>
                    <li>e. In the "give feedback" function in the Pocket Coach, there is the option to include a screenshot. Please be aware that if you include a screenshot when you use this function the screenshot will include whatever content is visible in the image. You do not have to include a screenshot.</li>
                    <li>f. In the "give feedback" function in the Pocket Coach, there is the option to include your email address. Please be aware that if you include your email address when you use this function it is linked to the feedback and/or screenshot you provide. You do not have to include your email address.</li>
                    <li>g. Whilst we deploy technical and organisational measures to protect your data against unauthorised access, loss, or alteration, we are unable to guarantee this on the basis of unforeseen technical interruption, hacking or other loss.</li>
                </ol>
                <br />
                <b>Account Usage</b>
                <ol className='list-none pl-4 space-y-1 mt-2'>
                    <li>a. By creating an account on the Verve Pocket Coach, you agree to keep your login credentials confidential and not to share them with anyone. Your account is for personal use only, and you are solely responsible for maintaining its security.</li>
                    <li>b. You are solely responsible for all activities that occur under your account. If you suspect any unauthorised use of your account, please notify us immediately.</li>
                </ol>
                <br />
                <b>Feedback and Suggestions</b>
                <ol className='list-none pl-4 space-y-1 mt-2'>
                    <li>a. As subscriber directly or via your Organisation, to the best of your ability, please provide feedback and suggestions regarding your experience with the Verve Pocket Coach. This feedback may include comments, ideas, or suggestions for improvements.</li>
                    <li>b. You acknowledge and agree that any feedback or suggestions you provide are voluntary and that we may use such feedback without any obligation to compensate you.</li>
                </ol>
                <br />
                <b>Verve Pocket Coach Usage and Limitations</b>
                <ol className='list-none pl-4 space-y-1 mt-2'>
                    <li>a. The Verve Pocket Coach is a first-generation release product using API connectivity with Open AI. This means it may contain bugs, errors, or other issues. You understand and accept that the Pocket Coach may not function perfectly during temporary online data disruptions.</li>
                    <li>b. We reserve the right to modify, suspend, or terminate the Pocket Coach or any of its features at any time without prior notice.</li>
                    <li>c. While we strive to provide accurate and reliable information through the Application, we do not guarantee the completeness, accuracy, or reliability of any content or advice provided.</li>
                </ol>
                <br />
                <b>Professional Advice</b>
                <ol className='list-none pl-4 space-y-1 mt-2'>
                    <li>a. The Verve Pocket Coach is intended for personal development and general informational purposes only. It should not be considered a substitute for professional advice or therapy.</li>
                </ol>
                <br />
                <b>Intellectual Property</b>
                <ol className='list-none pl-4 space-y-1 mt-2'>
                    <li>a. The Verve Pocket Coach, including its design, layout, features, and content, is protected by intellectual property laws and belongs to Verve Education.</li>
                    <li>b. You may not modify, reproduce, distribute, or create derivative works based on the Verve Pocket Coach without our prior written consent.</li>
                </ol>
                <br />
                <b>Limitation of Liability</b>
                <ol className='list-none pl-4 space-y-1 mt-2'>
                    <li>a. You expressly understand and agree that your use of the Verve Pocket Coach is at your own risk. We shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising from your use or inability to use this Application.</li>
                    <li>b. Whilst we focus on seamless and appropriate integration of third-party content into our systems, we cannot and shall not be responsible for any content, advice, or suggestions provided by third parties, including providers of AI knowledge content.</li>
                </ol>
                <br />
                <b>Governing Law and Dispute Resolution</b>
                <ol className='list-none pl-4 space-y-1 mt-2'>
                    <li>a. These terms and conditions shall be governed by and construed in accordance with the laws of Western Australia.</li>
                    <li>b. Any dispute arising out of or in connection with these terms and conditions shall be resolved through negotiation and, if necessary, through binding arbitration in the jurisdiction of Western Australia.</li>
                </ol>
                <br />
                <br />
                By using the Verve Pocket Coach, you acknowledge that you have read, understood, and agree to these terms and conditions. If you do not agree to these terms, please refrain from using the Application. These terms and conditions may be updated from time to time, and the updated version will request your acceptance.
            </div>

           {state&&state.no_footer?null:<div className='flex flex-col sticky bottom-0 z-50'>
                <div className='w-full rounded-tl-md rounded-tr-md container_shadow bg-sub-bg flex flex-row items-center justify-between px-4 py-3' >
                        <Button onClick={_on_dont} light={true} title={"Don't Accept"}/> 
                        <div/>
                        <Button onClick={_on_accept} title='I accept'/>
                </div>
            </div>}
        </div>
    );
};

export default  PrivacyInfo;

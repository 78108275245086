import React from 'react';
import RateText from '../../../utils/components/rate_text';
import { RootState } from '../../../../../db/redux/store';
import { connect } from 'react-redux';
import {m} from 'framer-motion';

class CommitToChange extends React.PureComponent<any,any>{
    is_express: boolean;

    constructor(props:any){
        super(props);
        this.state = {
            value:this.props.ratings,
        };
        this.is_express = Boolean(this.props.circ==='prepare_myself')

    };

    _on_change = (id:string, value:number) => {
        this.setState({value});
    }

    _next = () => {
        return {
            go: true,
            value:{
                commitment:this.state.value
            }
        };
    }

    render(): React.ReactNode {
        return (
            <m.div layout className="w-full h-full flex px-4  py-6 flex-col overflow-scroll action_container pb-40">
                <span className="font-quick text-text text-[16px] leading-[20px] flex mb-4 font-semibold">{this.is_express?'How prepared are you?':'How prepared and committed are you to being this way?'}</span>
                <span className="font-quick text-title font-medium text-[13px] leading-[16px] flex mb-4">{this.is_express?'Rate how prepared you now believe you will be for this circumstance.':'Congratulations on completing your Perception Project! Now it’s time to reflect on your readiness and commitment to this new way of approaching your circumstance.'}</span>
                {!this.is_express? (
                    <>
                        <br />
                        <span className="font-quick text-title font-medium text-[13px] leading-[16px] flex mb-4">How prepared and committed are you to embodying this new mindset and approach?</span>
                    </>
                ):null}
                <RateText no_color defaultRate={this.props.ratings} is_commitment no_select rl_text="Rate Your Level of Commitment" id="text" onChange={()=>{}} onChangeRate={this._on_change}/>

                {
                    this.state.value>9&&this.is_express?
                    <div className=''>
                       <span className=" flex-col font-quick text-bg font-medium text-[13px] leading-[16px] flex mt-4">
                              If the score is below 10, you can repeat the process focussing on what else is concerning you, including what disempowering emotions you are yet to address.
                       </span>
                    </div>
                    :null
                }
               {this.state.value>9?
             null
               :<m.span layout className=" flex-col font-quick text-title font-medium text-[13px] leading-[16px] flex mt-4">
                {this.is_express?
                     'If the score is below 10, you can repeat the process focussing on what else is concerning you, including what disempowering emotions you are yet to address.'
                :
                      <>
        If your score is below 10, that’s completely normal. Resistance is a natural part of the process and should be respected and understood.
        <br />
        <br />
        <b>Addressing Resistance</b>
        You can explore and work through any resistance by generating a new circumstance.
        <br />
        <br />
        <ol className='list-decimal mt-1 ml-5'>
            <li>Choose the same areas of struggle you identified for this circumstance.</li>
            <li>Select "Resistance" as your main theme and sub-themes such as perfectionism, fear of making mistakes, confrontation, conflict, or failure.</li>
            <li>
                After assessing the impact, scroll to the bottom of the emoji list and choose any 3 of the following emotions:
                <ul className='list-disc ml-5 mt-1'>
                    <li>Self-doubt</li>
                    <li>Resistant</li>
                    <li>Hesitant</li>
                    <li>Unprepared</li>
                    <li>Unconvinced</li>
                </ul>
            </li>
        </ol>
        <br />
        From there, complete the circumstance process to deepen your understanding and commitment.
        </>}
              
                </m.span>}
                <span className=" flex-col font-quick text-title font-medium text-[13px] leading-[16px] flex mt-4">
                    <span className='font-semibold text-text text-[16px] leading-[20px] mb-1 mt-4'>Upcoming Features</span>
                   Thank you for using the Pocket Coach. Below are some of the exciting features you can look forward to, and in the meantime, we welcome any feedback you would like to share on any aspect of the service and experience.
                    <ol className='list-disc ml-5 mt-1'>
                            <li><b>Your Personal Dashboard</b> to monitor and manage your overall progress.</li>
                            <li><b>New Personal Analytics</b> to help you better understand yourself.</li>
                            <li><b>Perception Projects Library</b> to store and revisit multiple projects.</li>
                            <li><b>Personal Audiobooks</b> exported from your completed Perception Projects.</li>
                            <li><b>Educational Videos and Tutorials</b> to guide and inspire you.</li>
                            <li><b>And much more</b> as your feedback continues to shape our development!</li>
                    </ol>
                    <br />
                    Thank you for trusting the Pocket Coach to help you approach your important challenges with clarity and purpose. We look forward to supporting you on your journey toward a more empowered and confident you!
                </span>
            </m.div>
        )
    }
};
// commit_to_change
const map_state_to_props = (state:RootState) => {
    return {
        ratings:state.app['commit_to_change']?.commitment||1,
        circ:state.app?.circgen_type?.selected
    }
};


export default connect(map_state_to_props,null,null,{forwardRef:true})(CommitToChange);
